import * as React from "react"
import Layout from "../../components/layoutAccount"
import '../../components/index.css'
import { TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "gatsby"
import InputAdornment from '@mui/material/InputAdornment';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';


const pageStyles = {
    color: "#232129",
    padding: "24px 12px",
    fontFamily: "Roboto, sans-serif, serif",
    maxWidth: "95%",
}

const CredentialsModal = ({ callback }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    function login() {
        fetch('https://api.thefoodapi.com/login', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ email, password })
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(data => callback(data))
            } else {
                setError("Login failed")
            }
        })
    }

    return <div style={{
        position: "fixed",
        inset: "0",
        background: "rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1101",
        backdropFilter: "blur(1px)"
    }}>
        <div style={{
            width: "20rem",
            maxWidth: "95%",
            background: "white",
            borderRadius: "8px",
            padding: "2rem 1rem"
        }}>
            <div style={{
                background: 'url(/icons/icon-large.png)',
                backgroundSize: "cover",
                width: "84px",
                height: "36px",
                margin: "auto"
            }}></div>
            <h2 style={{ textAlign: "center" }}>Renew your credentials</h2>
            <TextField size="small" InputLabelProps={{ shrink: true }} fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginTop: "1rem" }} />
            <TextField size="small" InputLabelProps={{ shrink: true }} type="password" fullWidth label="Password" onChange={(e) => setPassword(e.target.value)} sx={{ marginTop: "1rem" }} />
            <div
                onClick={login}
                style={{
                    padding: "0.5rem",
                    background: "#007788",
                    textAlign: "center",
                    borderRadius: "4px",
                    color: "white",
                    marginTop: "1rem",
                    cursor: "pointer"
                }}><b>Login</b></div>
            {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
    </div>
}

const KeyRow = ({ keyInfo }) => {
    return <div style={{ margin: "1rem 0" }}>
        <TextField defaultValue={keyInfo.token} disabled fullWidth sx={{ display: "flex", maxWidth: "40rem" }} inputProps={{
            style: { textOverflow: "ellipsis" }
        }}
            InputProps={{
                endAdornment: <InputAdornment position="start" title="Copy" onClick={() => {
                    navigator.clipboard.writeText(keyInfo.token);
                    alert("Copied!")
                }}><ContentPasteIcon style={{ cursor: "pointer" }} /></InputAdornment>
            }} />
    </div>
}

const DevelopmentKeys = ({ latestKeys }) => {
    const [keys, setKeys] = React.useState(latestKeys) // 

    React.useEffect(() => {
        setKeys(latestKeys)
    }, [latestKeys])

    console.log("latestKeys", latestKeys, "keys", keys)

    return <div>
        <h2>Developement keys<span style={{ color: "#00778885" }}> - free</span></h2>
        <div>Get access, for development purposes only, to a subset of our Food Information API, and Autocomplete API.</div>
        {keys.length === 0 ? <>
            <div style={{ padding: "1rem 0", color: "grey" }}>You do not have any development key yet.</div>
            <div style={{
                display: "flex"
            }}>
                <div className="clickable" style={{
                    padding: "1rem",
                    background: "#007788",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "4px"
                }}>Create a development key</div>
            </div>
        </> :
            <div>{keys.map(k => <KeyRow key={k.token} keyInfo={k} />)}</div>}
    </div>
}

const ProductionKeys = ({ latestKeys }) => {
    const [keys, setKeys] = React.useState(latestKeys) // 

    React.useEffect(() => {
        setKeys(latestKeys)
    }, [latestKeys])

    return <div>
        <h2>Production keys<span style={{ color: "#00778885" }}> - $99/year</span></h2>
        <div>Get full access, for production and development purposes, to the entire Food Information API, and Autocomplete API.</div>
        {keys.length === 0 ? <>
            <div style={{ padding: "1rem 0", color: "grey" }}>You do not have any development key yet.</div>
            <div style={{
                display: "flex"
            }}>
                <div onClick={() => {
                    fetch('https://api.thefoodapi.com/checkout-session', {
                        method: "post",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        credentials: "include",
                        body: JSON.stringify({ product: "Production key" })
                    }).then(resp => {
                        if (resp.status === 200) {
                            resp.json().then(data => {
                                if (data.url) window.location = data.url
                            })
                        }
                    })
                }}
                    className="clickable" style={{
                        padding: "1rem",
                        background: "#007788",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "4px"
                    }}>Create a production key</div>
            </div>
        </> : <>
            <div>{keys.map(k => <KeyRow key={k.token} keyInfo={k} />)}</div>
        </>
        }
    </div>
}

const IndexPage = ({ location }) => {
    const [accountInfo, setAccountInfo] = React.useState(null)
    const [accountKeys, setAccountKeys] = React.useState([])
    const [askCredentials, setAskCredentials] = React.useState(false)
    const [isWelcome, setIsWelcome] = React.useState(false)

    React.useEffect(() => {
        if (window !== undefined) {
            const params = new URLSearchParams(window.location.search)
            setIsWelcome(params.get("welcome") === "true")
        }

        fetch('https://api.thefoodapi.com/account/keys', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 403) {
                setAskCredentials(true)
            } else if (resp.status === 200) {
                resp.json().then(data => setAccountKeys(data))
            }
        })
    }, [accountInfo])

    return (
        <Layout location={location}>
            {askCredentials && <CredentialsModal callback={(accountInfo) => {
                setAskCredentials(false)
                setAccountInfo(accountInfo)
            }} />}
            <div style={pageStyles}>
                <DevelopmentKeys latestKeys={accountKeys.filter(k => k.env === "dev")} />
                <br />
                <br />
                <ProductionKeys latestKeys={accountKeys.filter(k => k.env === "prod")} />
            </div>
            {isWelcome && <div style={{
                position: "fixed",
                inset: "0",
                background: "rgba(0,0,0,0.5)",
                backdropFilter: "brightness(0.5)",
                zIndex: "1200"
            }}>
                <div style={{ display: "flex", minWidth: "100%", minHeight: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div style={{
                        width: "25rem",
                        maxWidth: "calc(100% -4rem)",
                        padding: "1rem",
                        background: "white",
                        textAlign: "center"
                    }}>
                        <h2>Welcome!</h2>
                        <p>Your account is now verified. The next step is to:</p>
                        <div
                            onClick={() => setIsWelcome(false)}
                            className="clickable" style={{
                                width: "calc(100% - 2rem)",
                                background: "#007788",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "4px",
                                padding: "1rem"
                            }}>Get your API keys</div>
                        <p>Or</p>
                        <Link to="/account/snapshots" className="clickable" style={{ textDecoration: "none" }}>
                            <div style={{
                                width: "calc(100% - 2rem)",
                                background: "#007788",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "4px",
                                padding: "1rem"
                            }}>Download a Snapshot</div>
                        </Link>
                    </div>
                </div>
            </div>}
        </Layout>
    )
}

export default IndexPage

export const Head = () => <title>Pricing Page</title>
